import React from 'react';
import { createPortal } from 'react-dom';
import { ModalWrapper, ModalContainer, ModalContent, CloseBtn } from './Modal.styled';

const ModalGateway = ({ children }) => {
  const target = (typeof (window) !== 'undefined') ? document.body : null;
  return (typeof (window) === 'undefined') ? null : createPortal(children, target);
};

const Modal = ({ visibility, setVisibility, children }) => {
  const [modalAnimationState, setModalAnimationState] = React.useState('closed');

  const closeModal = () => {
    setModalAnimationState('closing');
    setTimeout(() => setVisibility('unvisible'), 500);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => setModalAnimationState(visibility === 'visible' ? 'opened' : 'closed'), 100);
    return () => clearTimeout(timeout)
  }, [visibility]);

  return (
    visibility === 'visible' ? (
      <ModalGateway>
        <ModalWrapper state={modalAnimationState}>
          <ModalContainer state={modalAnimationState}>
            <CloseBtn onClick={closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
              </svg>
            </CloseBtn>
            <ModalContent>
              {children}
            </ModalContent>
          </ModalContainer>
        </ModalWrapper>
      </ModalGateway>
    ) : null
  );
};

export default Modal;
