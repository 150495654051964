import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import MenuTrigger from '../MenuTrigger';
import Navigation from '../Navigation';
import { HeaderWrapper, HeaderRow, Separator, Contact, ContactItem, Certificate, Logo } from './Header.styled';
import logo from '../../assets/images/logo.png';

const Header = ({ isHome }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contact: cockpitContactPage {
        street {
          value
        }
        city {
          value
        }
        postalCode {
          value
        }
        phone {
          value {
            value
          }
        }
        email {
          value
        }
      }
    }
  `);

  const [open, setOpen] = React.useState(false);
  const [fixed, setFixed] = React.useState(false);

  React.useEffect(() => {
    const header = document.querySelector('header');
    const headerTop = header.firstChild;
    const headerTopH = headerTop.offsetHeight;
    const fixedHeader = () => setFixed(window.scrollY >= headerTopH);
    fixedHeader();
    window.addEventListener('scroll', fixedHeader);
    return () => window.removeEventListener('scroll', fixedHeader);
  }, []);

  return (
    <HeaderWrapper>
      <HeaderRow>
        <div className="container">
          <Contact>
            <ContactItem>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
              </svg>
              {data.contact.phone.value.map(({ value }, i) => (
                <span key={`header-phone-${i}`}><a href={`tel:${value}`}>{value}</a>{i < data.contact.phone.value.length - 1 && ', '}</span>
              ))}
            </ContactItem>
            <ContactItem>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
              </svg>
              <a href={`mailto:${data.contact.email.value}`}>{data.contact.email.value}</a>
            </ContactItem>
            <ContactItem>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
              </svg>
              <span>{data.contact.street.value}, {data.contact.postalCode.value} {data.contact.city.value}</span>
            </ContactItem>
          </Contact>
          <Certificate><strong>Certyfikat</strong> ISO 9001:2008</Certificate>
        </div>
      </HeaderRow>
      <Separator />
      <HeaderRow fixed={fixed} isHome={isHome}>
        <div className="container">
          <Link to="/">
            <Logo src={logo} alt="Grupa EXPOZYTOR" fixed={fixed} />
          </Link>
          <Navigation open={open} setOpen={setOpen} fixed={fixed} />
          <MenuTrigger open={open} setOpen={setOpen} />
        </div>
      </HeaderRow>
    </HeaderWrapper>
  );
};

export default Header;
