import styled from '@emotion/styled';

export const Nav = styled.nav`
  ul {
    list-style: none;
    margin: 0;

    li {
      margin: 0;

      a {
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        color: inherit;
        text-decoration: none;
        text-transform: uppercase;
        opacity: .6;
        transition: opacity .3s;

        &.active,
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 991px) {
    position: absolute;
    z-index: 0;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    height: ${({ height }) => height ? height + 40 + 'px' : 0};
    max-height: ${({ maxHeight }) => maxHeight}px;
    padding: ${({ open }) => open ? '0 0 20px' : 0};
    background-color: #FFFFFF;
    box-shadow: ${({ open }) => open ? '0 5px 5px rgba(0, 0, 0, .1)' : 'none'};
    transition: height .5s ease, max-height .5s ease, padding .5s ease, box-shadow ${({ open }) => open ? '.5s' : '.5s .5s'} ease;
    overflow-x: hidden;

    > div {
      width: 100%;
      max-height: 100%;
      box-sizing: content-box;
      padding-right: 20px;
      overflow-y: auto;
    }

    ul {
      li {
        display: block;

        a {
          display: block;
          padding: 10px calc((100% - 690px) / 2);
        }
      }
    }
  }

  @media (max-width: 767px) {
    ul {
      li {
        a {
          padding: 10px calc((100% - 510px) / 2);
        }
      }
    }
  }

  @media (max-width: 575px) {
    ul {
      li {
        a {
          padding: 10px 15px;
        }
      }
    }
  }

  @media (min-width: 992px) {
    ul {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin: 0 -8px;

      li {
        display: block;
        padding: 5px 8px;

        a {
          font-size: 14px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    ul {
      margin: 0 -15px;

      li {
        padding: 5px 15px;

        a {
          font-size: 15px;
        }
      }
    }
  }
`;
