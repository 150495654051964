import styled from '@emotion/styled';

export const StyledLayout = styled.div`
  .CookieConsent {
    z-index: 1020 !important;
    background-color: #FFF !important;
    box-shadow: 0 -5px 5px rgba(0, 0, 0, .1);
    color: #555555 !important;
    font-size: .875rem;

    a {
      color: #555555;
      transition: opacity .3s;
      text-decoration: none;
      opacity: .75;

      &:hover {
        opacity: 1;
      }
    }

    button {
      background-color: #d72226 !important;
      color: #F5F5F5 !important;
      padding: 5px 20px !important;
      font-weight: 600;
    }
  }
`;

export const Main = styled.main`
  padding-top: 208px;

  @media (min-width: 768px) {
    padding-top: 159px;
  }

  @media (min-width: 992px) {
    padding-top: 123px;
  }

  @media (min-width: 1200px) {
    padding-top: 133px;
  }

  @media (min-width: 1440px) {
    padding-top: 136px;
  }
`;
