import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ lang = 'pl', meta = [], title = 'home', description = '', children }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title === 'home' ? site.siteMetadata.title : title}
      titleTemplate={title === 'home' ? `%s` : `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'robots',
          content: 'noindex'
        },
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title === 'home' ? site.siteMetadata.title : title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl
        },
        {
          property: 'og:locale',
          content: 'pl_PL'
        }
      ].concat(meta)}
    >
      {children}
    </Helmet>
  );
};

export default Seo;
