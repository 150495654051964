import React from 'react';
import { Link } from 'gatsby';
import { Nav } from './Navigation.styled';

const Navigation = ({ open, setOpen, fixed }) => {
  const [maxHeight, setMaxHeight] = React.useState();
  const [height, setHeight] = React.useState(0);
  const menu = React.useRef();

  React.useEffect(() => {
    const header = document.querySelector('header');
    const headerBottom = header.lastChild;
    setMaxHeight(window.innerHeight - (fixed ? headerBottom.offsetHeight : header.offsetHeight));
    open ? setHeight(menu.current.offsetHeight) : setHeight(0);
  }, [open, fixed]);

  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <Nav open={open} height={height} maxHeight={maxHeight}>
      <div>
        <ul ref={menu}>
          <li>
            <Link to="/" onClick={handleLinkClick} activeClassName="active">Strona główna</Link>
          </li>
          <li>
            <Link to="/o-firmie" onClick={handleLinkClick} activeClassName="active">O firmie</Link>
          </li>
          <li>
            <Link to="/oferta" onClick={handleLinkClick} activeClassName="active">Oferta</Link>
          </li>
          <li>
            <Link to="/uslugi" onClick={handleLinkClick} activeClassName="active">Usługi</Link>
          </li>
          <li>
            <Link to="/realizacje" onClick={handleLinkClick} activeClassName="active">Realzacje</Link>
          </li>
          <li>
            <Link to="/kontakt" onClick={handleLinkClick} activeClassName="active">Kontakt</Link>
          </li>
          <li>
            <Link to="/fundusze-eu" onClick={handleLinkClick} activeClassName="active">Fundusze EU</Link>
          </li>
        </ul>
      </div>
    </Nav>
  );
};

export default Navigation;
