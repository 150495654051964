import React from 'react';
import { Button } from './MenuTrigger.styled';

const MenuTrigger = ({ open, setOpen }) => {
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Button open={open} onClick={handleClick}>
      <span></span>
      <span></span>
      <span></span>
    </Button>
  );
};

export default MenuTrigger;
