import styled from '@emotion/styled';

export const FooterWrapper = styled.footer`
  padding: 60px 0 20px;
  background-color: #f5f5f5;

  @media (min-width: 1440px) {
    padding: 80px 0 40px;
  }
`;

export const FooterColumn = styled.div`
  margin: 0 0 2.5rem;
  text-align: center;

  .footer-title {
    display: block;
    margin: 0 0 1.25rem;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    a {
      display: block;
      color: inherit;
      text-decoration: none;
      transition: opacity .3s;

      &:hover {
        opacity: .6;
      }
    }

    &--red {
      color: #d72226;
    }
  }

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 1200px) {
    margin: 0;
  }
`;

export const Logo = styled.img`
  display: block;
  height: 50px;
  margin: 0 auto 2.5rem;

  @media (min-width: 768px) {
    margin: 0 auto 3.5rem 0;
  }
`;

export const Copyright = styled.p`
  margin: 0;
  font-size: .875rem;
  line-height: 1.25rem;
  text-align: center;

  a {
    color: inherit;
    text-decoration: none;
    transition: opacity .3s;

    &:hover {
      opacity: .6;
    }
  }

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (min-width: 1200px) {
    margin: 60px 0 0;
  }

  @media (min-width: 1440px) {
    margin: 80px 0 0;
  }
`;

export const Address = styled.address`
  margin: 0;
  font-style: normal;

  p {
    position: relative;
    margin: 0;
    padding: 0 0 0 26px;
    line-height: 2;

    svg {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin: -8px 0 0;
      fill: #696566;
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: opacity .3s;

      &:hover {
        opacity: .6;
      }
    }
  }

  > div {
    margin: 0 0 2.5rem;

    @media (max-width: 767px) {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: block;
    margin: 0;

    a {
      display: block;
      color: inherit;
      line-height: 2;
      text-decoration: none;
      transition: opacity .3s;

      &:hover {
        opacity: .6;
      }
    }
  }
`;
