import styled from '@emotion/styled';

export const HeaderWrapper = styled.header`
  position: absolute;
  z-index: 1010;
  top: 0;
  left: 0;
  right: 0;;
  background: #FFFFFF;
`;

export const HeaderRow = styled.div`
  position: relative;
  width: 100%;
  padding: 15px 0;
  background-color: #FFFFFF;

  .container {
    position: static;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  &:first-of-type {
    .container {
      flex-flow: column nowrap;
      justify-content: center;
    }
  }

  &:last-of-type {
    position: ${({ fixed }) => fixed ? 'fixed' : 'static'};
    top: 0;
    box-shadow: ${({ fixed, isHome }) => fixed || !isHome ? '0 5px 5px rgba(0, 0, 0, .1)' : 'none'};
    transition: box-shadow .3s, padding .3s;
  }

  @media (min-width: 992px) {
    &:first-of-type {
      .container {
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  @media (min-width: 1200px) {
    &:last-of-type {
      padding: ${({ fixed }) => fixed ? '15px 0' : '20px 0'};
    }
  }
`;

export const Separator = styled.span`
  display: block;
  width: 100%;
  max-width: 1800px;
  height: 1px;
  margin: 0 auto;
  background-color: #eeeeee;
`;

export const Contact = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 -10px 10px;

  @media (min-width: 768px) {
    flex-flow: row wrap;
  }

  @media (min-width: 992px) {
    margin: 0 -10px;
  }
`;

export const ContactItem = styled.span`
  position: relative;
  margin: 0 10px;
  padding: 0 0 0 26px;
  font-size: .875rem;

  svg {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin: -8px 0 0;
    fill: #696566;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: opacity .3s;

    &:hover {
      opacity: .5;
    }
  }
`;

export const Certificate = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: .875rem;
`;

export const Logo = styled.img`
  display: block;
  height: 35px;
  margin: 0 auto 0 0;
  transition: height .3s;

  @media (min-width: 1200px) {
    height: ${({ fixed }) => fixed ? '35px' : '40px'};
  }
`;
