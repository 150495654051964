import styled from '@emotion/styled';

export const ModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 1090;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ state }) => state === 'opened' ? 'rgba(0, 0, 0, .5)' : 'rgba(0, 0, 0, 0)'};
  transition: background-color .5s;
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: ${({ state }) => state === 'opened' ? '50%' : 'calc(50% + 100px)'};
  left: 50%;
  width: 800px;
  max-width: 90vw;
  max-height: 90vh;
  padding: 50px 20px 20px;
  background-color: #FFFFFF;
  transform: translate(-50%, -50%);
  opacity: ${({ state }) => state === 'opened' ? '1' : '0'};
  transition: opacity .5s, top .5s;

  @media (min-width: 576px) {
    padding: 50px 50px 30px;
  }

  @media (min-width: 992px) {
    padding: 60px 60px 40px;
  }
`;

export const CloseBtn = styled.button`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 20px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    display: block;
    width: 14px;
    height: 14px;
    fill: #d72226;
    transition: fill .3s;
  }

  @media (min-width: 992px) {
    padding: 23px;
  }
`;

export const ModalContent = styled.div`
  max-height: calc(90vh - 70px);
  overflow-y: auto;

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  p {
    margin: 0;
    width: 100%;
    max-width: 100%;

    &:not(:last-child) {
      margin: 0 0 1rem;
    }
  }

  a {
    color: #d72226;
    text-decoration: none;
    font-weight: bolder;
    transition: opacity .3s;

    &:hover {
      opacity: .75;
    }
  }

  @media (min-width: 576px) {
    max-height: calc(90vh - 80px);
  }

  @media (min-width: 992px) {
    max-height: calc(90vh - 100px);
  }
`;
