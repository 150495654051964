import styled from '@emotion/styled';

export const Button = styled.button`
  display: block;
  position: relative;
  z-index: 1020;
  width: 40px;
  height: 40px;
  margin: 0 0 0 auto;
  padding: 0;
  border: none;
  outline: none;
  background: none;

  &:hover {
    span {
      &:nth-of-type(2) {
        width: 20px;
      }
    }
  }

  span {
    display: block;
    position: absolute;
    right: 8px;
    width: 24px;
    height: 2px;
    background-color: #696566;

    &:nth-of-type(1) {
      top: ${({ open }) => open ? 'calc(50% - 1px)' : '11px'};
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'none'};
      transform-origin: center;
      transition: ${({ open }) => open ? 'background-color .3s, transform .3s .3s, top .3s' : 'background-color .3s, transform .3s, top .3s .3s'};
    }

    &:nth-of-type(2) {
      top: 19px;
      opacity: ${({ open }) => open ? '0' : '1'};
      transition: background-color .3s, opacity .3s, width .3s;
    }

    &:nth-of-type(3) {
      bottom: ${({ open }) => open ? 'calc(50% - 1px)' : '11px'};
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'none'};
      transform-origin: center;
      transition: ${({ open }) => open ? 'background-color .3s, transform .3s .3s, bottom .3s' : 'background-color .3s, transform .3s, bottom .3s .3s'};
    }
  }

  @media (min-width: 992px) {
    display: none;
  }
`;
