import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Cookies from 'js-cookie';
import CookieConsent from 'react-cookie-consent';
import Modal from '../Modal';
import Header from '../Header';
import Footer from '../Footer';
import { StyledLayout, Main } from './Layout.styled';

const Layout = ({ isHome, children }) => {
  const data = useStaticQuery(graphql`
    query WelcomePopupQuery {
      cockpitWelcomePopup {
        content {
          value
        }
      }
    }
  `);

  const cookieName = 'welcomePopupVisibility';
  const [modalVisibility, setModalVisibility] = React.useState('unvisible');

  const setCookie = (name, value) => {
    const expires = 1;
    let secure = null;

    if (typeof (window) !== 'undefined') {
      secure = window.location ? window.location.protocol === "https:" : true;
    }

    let options = { expires, secure };
    Cookies.set(name, value, options);
  };

  const getCookie = () => Cookies.get(cookieName);

  const setModalVisibilityAndCookie = visibility => {
    setModalVisibility(visibility);
    setCookie(cookieName, 'unvisible');
  };

  React.useEffect(() => {
    const cookieValue = getCookie();

    const timeout = setTimeout(() => {
      setModalVisibility(cookieValue ? cookieValue : 'visible');
    }, 2000);

    const smoothScroll = event => {
      event.preventDefault();
      const target = event.currentTarget.hash;
      scrollTo(target);
    };

    document.querySelectorAll('.smooth-scroll').forEach(element => {
      element.addEventListener('click', smoothScroll);
    });

    return () => {
      clearTimeout(timeout);
      document.querySelectorAll('.smooth-scrll').forEach(element => {
        element.removeEventListener('click', smoothScroll);
      });
    }
  }, []);

  return (
    <StyledLayout>
      <Header isHome={isHome} />
      <Main>{children}</Main>
      <Footer />
      <CookieConsent buttonText="OK">
        Nasza strona używa ciasteczek. Możesz je wyłączyć w ustawieniach przeglądarki. Więcej informacji znajdziesz w <Link to="/polityka-prywatnosci">polityce prywatności</Link>.
      </CookieConsent>
      <Modal visibility={modalVisibility} setVisibility={setModalVisibilityAndCookie}>
        <div dangerouslySetInnerHTML={{ __html: data.cockpitWelcomePopup.content.value }}></div>
      </Modal>
    </StyledLayout>
  );
};

export default Layout;
